<template>
   <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M89.7917 25C95.1995 25 99.5834 20.5228 99.5834 15C99.5834 9.47715 95.1995 5 89.7917 5C84.3839 5 80 9.47715 80 15C80 20.5228 84.3839 25 89.7917 25Z"
         fill="#FC2B36"
      />
      <path
         d="M39.5 80C52.4787 80 63 69.2548 63 56C63 42.7452 52.4787 32 39.5 32C26.5213 32 16 42.7452 16 56C16 69.2548 26.5213 80 39.5 80Z"
         fill="#FC2B36"
      />
      <g filter="url(#filter0_d)">
         <path
            d="M60 80C82.0914 80 100 62.0914 100 40C100 17.9086 82.0914 0 60 0C37.9086 0 20 17.9086 20 40C20 62.0914 37.9086 80 60 80Z"
            fill="url(#paint0_linear)"
         />
         <path
            d="M60 79.5C81.8152 79.5 99.5 61.8152 99.5 40C99.5 18.1848 81.8152 0.5 60 0.5C38.1848 0.5 20.5 18.1848 20.5 40C20.5 61.8152 38.1848 79.5 60 79.5Z"
            stroke="url(#paint1_linear)"
         />
      </g>
      <path
         d="M60 60C48.954 60 40 51.046 40 40C40 28.954 48.954 20 60 20C71.046 20 80 28.954 80 40C80 51.046 71.046 60 60 60ZM60 56C64.2435 56 68.3131 54.3143 71.3137 51.3137C74.3143 48.3131 76 44.2435 76 40C76 35.7565 74.3143 31.6869 71.3137 28.6863C68.3131 25.6857 64.2435 24 60 24C55.7565 24 51.6869 25.6857 48.6863 28.6863C45.6857 31.6869 44 35.7565 44 40C44 44.2435 45.6857 48.3131 48.6863 51.3137C51.6869 54.3143 55.7565 56 60 56ZM58 46H62V50H58V46ZM58 30H62V42H58V30Z"
         fill="white"
      />
      <defs>
         <filter
            id="filter0_d"
            x="0"
            y="0"
            width="100"
            height="100"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
         >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dx="-10" dy="10" />
            <feGaussianBlur stdDeviation="5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
         </filter>
         <linearGradient id="paint0_linear" x1="37" y1="66.5" x2="90" y2="14.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F14851" />
            <stop offset="1" stop-color="#F84A53" stop-opacity="0.25" />
         </linearGradient>
         <linearGradient id="paint1_linear" x1="34" y1="67" x2="90" y2="15.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" stop-opacity="0.25" />
            <stop offset="1" stop-color="white" stop-opacity="0.05" />
         </linearGradient>
      </defs>
   </svg>
</template>

<script>
export default {
   name: 'IconRemove',
};
</script>
