<template>
   <AmcModal hide-cancel ref="modal">
      <template #header> </template>
      <AmcText class="pt-5 pb-3 text-center">
         <IconRemove />
         <AmcText class="fw-bold h4" Color="#EF2934">{{ $t('removeModal.Silme İşlemi') }}</AmcText>
         <AmcText class="text-secondary">{{ $t('removeModal.Silmek istediğinizden emin misiniz') }}?</AmcText>
      </AmcText>
      <AmcText>
         <input
            type="password"
            class="form-control shadow-none"
            :placeholder="$t('placeholders.Şifrenizi girinizi')"
            v-model="checkPassword.password"
         />
      </AmcText>
      <template slot="actions">
         <button type="button" class="btn btn-light rounded-pill px-5" data-bs-dismiss="modal">
            {{ $t('modalActions.Vazgeç') }}
         </button>
         <button
            @click="removeItem(item)"
            type="button"
            class="btn btn-danger rounded-pill px-5 text-white"
            data-bs-dismiss="modal"
         >
            {{ $t('modalActions.Evet, Sil') }}
         </button>
      </template>
   </AmcModal>
</template>

<script>
import IconRemove from '@/components/icons/IconRemove';
import CheckPassword from '@/api/CheckPassword';
import Form from '@/libs/form';
import ErrorHandler from '@/libs/error-handler';
import Notify from '@/libs/notify';

export default {
   name: 'AmcRemoveModal',
   components: {
      IconRemove,
   },
   data() {
      return {
         item: null,
         checkPassword: Form.create(['password']),
         booleanPassword: null,
      };
   },
   methods: {
      async check() {
         try {
            const response = await CheckPassword.index(this.checkPassword.toObject());
            this.booleanPassword = response.success;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.checkPassword.password = '';
         }
      },
      async removeItem(item) {
         await this.check();
         if (this.booleanPassword) {
            this.$emit('remove', item);
         } else {
            Notify.error(this.$t('notify.Lütfen şifrenizi kontrol ediniz'));
            return;
         }
      },
      show(id) {
         this.item = id;
         this.$refs.modal.show();
      },
      hide() {
         this.item = null;
         this.$refs.modal.hide();
      },
   },
};
</script>
